<template>
  <div>
    <v-dialog
      v-model="showAddExercise"
      :fullscreen="isPhone"
      :width="dialogWidth"
      overlay-opacity=".95"
    >
      <AddExercise @done="exerciseAdded" @cancel="showAddExercise = false" />
    </v-dialog>
    <v-sheet rounded class="pa-2 transparent charcoalTileMenu">
      <v-card-actions class="px-0 pt-0">
        <v-text-field
          dark
          filled
          clearable
          :color="!loaded ? 'accent' : 'success'"
          :label="'Search ' + loadedList.length + '  exercises or'"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          single-line
          hide-details
          @focus="$event.target.select()"
          @click:clear="debouncedSearch"
          @keydown.enter="debouncedSearch"
        />
        <v-spacer v-if="isPhone" />
        <BaseActionButton
          icon="mdi-magnify"
          text
          x-large
          class="transparent paper--text"
          color="progressActive"
          label=""
          title="Search Exercises"
          @clickedThis="debouncedSearch"
        />
      </v-card-actions>

      <v-card-actions class="pa-0 ml-n2 mb-2">
        <v-spacer />
        <BaseActionButton
          icon="mdi-plus-circle-outline"
          label="Create Exercise"
          title="Create Exercise"
          text
          small
          color="silver"
          @clickedThis="showAddExercise = true"
        />
      </v-card-actions>
    </v-sheet>
    <BaseLoading :loaded="loaded" />
    <v-row class="mt-2"  dense>
      <v-col v-for="item in exercises" :key="item.id" cols="12" md="6">
        <v-card rounded flat class="transparent charcoalTile mb-2">
          <v-sheet
            class="transparent paper--text"
            :color="isSelected(item.id) ? 'charcoal lighten-1' : 'transparent'"
          >
            <v-card-actions
              @click="showExerciseInfoDialog(item.id)"
              :class="textSizeSmall + ' link'"
            >
              <span class="pl-1">
                {{
                  item.name.length > 40
                    ? item.name.substr(0, 40) + '...'
                    : item.name
                }}
              </span>
              <v-spacer />
              <v-icon
                :title="'Dificulty: ' + item.dificulty"
                :color="dificultyColor(item.dificulty, false)"
              >
                mdi-checkbox-blank
              </v-icon>
            </v-card-actions>
          </v-sheet>
          <v-sheet class="px-1 transparent">
            <v-card-actions class="py-0 pr-0">
              <template v-if="isSelected(item.id)">
                <v-icon
                  @click="exerciseRemoveLast(item)"
                  class="mr-n2 ml-n2"
                  color="green"
                >
                  mdi-check-circle-outline
                </v-icon>
                <span class="caption silver--text pl-3 py-2">
                  Added
                </span>
              </template>
              <v-btn
                @click="selectExercise(item)"
                v-else
                large
                text
                color="silver"
                class="ml-n3 btn-fix"
              >
                <v-icon color="silver" large>
                  mdi-plus-circle
                </v-icon>
                TO WORKOUT
              </v-btn>
              <v-spacer />
              <v-checkbox
                class="enlarged-checkbox mr-n3 btn-fix"
                off-icon="mdi-chevron-down"
                on-icon="mdi-chevron-up"
                dark
                v-model="item.showDetails"
                label=""
              />
            </v-card-actions>
            <v-card-text
              style="overflow-y: auto"
              class="silver--text noscroll-x px-0 pt-0"
              v-if="item.showDetails"
            >
              <v-card-actions class="pa-0">
                <v-spacer />
                <BaseTag
                  v-if="item.public == 'Yes'"
                  label="Public"
                  icon="mdi-bullhorn"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  v-if="item.resistance == 'Yes'"
                  label="Resistance"
                  icon="mdi-weight-lifter"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  v-if="item.bodyweight == 'Yes'"
                  label="Bodyweight"
                  icon="mdi-yoga"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  v-if="item.calisthenics == 'Yes'"
                  label="Calisthenics"
                  icon="mdi-human-handsup"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  v-if="item.cardio == 'Yes'"
                  label="Cardio"
                  icon="mdi-run-fast"
                  :tagsize="tagsize"
                  class="mr-2"
                  color="transparent paper--text"
                />
                <v-spacer />
              </v-card-actions>
              <v-card-actions class="pa-0">
                <v-spacer />
                <BaseTag
                  :label="item.force"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  :label="item.mechanics"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <BaseTag
                  :label="item.utility"
                  :tagsize="tagsize"
                  color="transparent paper--text"
                />
                <v-spacer />
              </v-card-actions>
              <v-card-title
                class="text-h6 py-0"
                v-if="JSON.parse(item.equipment)"
              >
                <v-divider class="charcoal lighten-1" />
                <span class="mx-2">Equipment</span>
                <v-divider class="charcoal lighten-1" />
              </v-card-title>
              <span
                v-for="p in JSON.parse(item.equipment)"
                :key="p.equipmentid"
              >
                <v-icon x-small class="progressActive--text">
                  mdi-blur
                </v-icon>
                {{ p.name }}
              </span>
              <v-card-title
                class="text-h6 pb-0"
                v-if="getMuscles(item.muscles, 'Primary')"
              >
                <v-divider class="charcoal lighten-1" />
                <span class="mx-2">Primary Muscles</span>
                <v-divider class="charcoal lighten-1" />
              </v-card-title>
              <span
                v-for="p in getMuscles(item.muscles, 'Primary')"
                :key="p.muscleid"
              >
                <v-icon x-small class="progressActive--text">
                  mdi-blur
                </v-icon>
                {{ p.name }}
              </span>
              <v-card-title
                class="text-h6 pb-0"
                v-if="getMuscles(item.muscles, 'Secondary')"
              >
                <v-divider class="charcoal lighten-1" />
                <span class="mx-2">Secondary Muscles</span>
                <v-divider class="charcoal lighten-1" />
              </v-card-title>
              <span
                v-for="p in getMuscles(item.muscles, 'Secondary')"
                :key="p.muscleid"
              >
                <v-icon x-small class="progressActive--text">
                  mdi-blur
                </v-icon>
                {{ p.name }}
              </span>
              <v-card-title
                class="text-h6 pb-0"
                v-if="getMuscles(item.muscles, 'Stabilizers')"
              >
                <v-divider class="charcoal lighten-1" />
                <span class="mx-2">Stabilizing Muscles</span>
                <v-divider class="charcoal lighten-1" />
              </v-card-title>
              <span
                v-for="p in getMuscles(item.muscles, 'Stabilizers')"
                :key="p.muscleid"
              >
                <v-icon x-small class="progressActive--text">
                  mdi-blur
                </v-icon>
                {{ p.name }}
              </span>
            </v-card-text>
          </v-sheet>
        </v-card>
      </v-col>
      <v-dialog
        v-model="showExerciseInfo"
        :fullscreen="isPhone"
        :width="dialogWidth"
        overlay-color="charcoal"
        overlay-opacity=".97"
      >
        <ExerciseInfo
          :exerciseid="exerciseid"
          @keydown.esc="cancelInfo"
          @cancel="cancelInfo"
          @done="doneInfo"
          @swipe_off="cancelInfo"
        />
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
const AddExercise = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddExercise.vue')
export default {
  components: { ExerciseInfo, AddExercise },
  name: 'ExercisesList',
  mixins: [util],
  props: {
    type: {
      type: String
    }
  },
  data() {
    return {
      exercises: [],
      loadedList: [],
      search: null,
      debounce: null,
      pagesize: 500,
      currentPage: 0,
      bottom: false,
      loaded: false,
      showExerciseInfo: false,
      showAddExercise: false,
      repschemeid: 'hzWVL',
      exerciseid: null,
      tagsize: 'small',
      previousParams: '',
      variations: 'Yes',
      hasNewExercise: true
    }
  },
  beforeMount() {
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
    window.removeEventListener('message', this.receiveMessage)
  },
  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    debouncedSearch() {
      this.searching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadPage()
      }, 50)
      this.searching = false
    },
    setResults(value) {
      this.exercises = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    getMuscles(allMuscles, type) {
      if (this.isEmpty(allMuscles)) return false
      let muscles = JSON.parse(allMuscles)
      muscles = muscles.filter(el => {
        return el.type == type
      })
      return muscles
    },
    selectExercise(exercise) {
      exercise.workoutType = this.type
      if (!exercise.sort > 0) {
        exercise.sort = this.exercisesListSize + 1
      }
      this.exerciseAdd(exercise)
    },
    isSelected(id) {
      return this.exercisesList.some(sel => sel.id === id)
    },
    cancelInfo() {
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    doneInfo() {
      this.loadPage()
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    showExerciseInfoDialog(exerciseid) {
      this.exerciseid = exerciseid
      this.showExerciseInfo = true
    },
    exerciseAdded(exercise) {
      exercise.workoutType = this.type
      if (!exercise.sort > 0) {
        exercise.sort = this.exercisesListSize + 1
      }
      this.exerciseAdd(exercise)
      this.hasNewExercise = true
      this.loadPage()
      this.showAddExercise = false
    },
    loadPage() {
      this.loaded = false
      let searchTerm =
        this.search == null || this.search == '' ? 'all' : this.search

      const urlParams = new URLSearchParams()
      urlParams.append('search', searchTerm)
      urlParams.append('variations', this.variations)

      let keepScrolling =
        this.previousParams == null ||
        this.previousParams.toString() == urlParams.toString()

      if (!keepScrolling || this.hasNewExercise) {
        this.currentPage = 0
        this.refresh = true
      }

      return axios
        .get(
          this.baseURL +
            '/exercises/simple/scroll/' +
            this.currentPage +
            '/' +
            this.pagesize,
          { params: urlParams }
        )
        .then(response => {
          if (response.status == 200) {
            if (response.data.data) {
              let newResponse = response.data.data
              newResponse.forEach(el => {
                el.repschemeid = this.repschemeid
              })

              if (!this.refresh) {
                this.exercises = this.exercises.concat(newResponse)
              } else {
                this.exercises = newResponse
              }
              newResponse.forEach(el => {
                el.repschemeid = this.repschemeid
              })

              this.loadedList = this.exercises.slice()
            }
            this.hasNewExercise = false
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    ...mapActions('builder', [
      'exerciseAdd',
      'exerciseRemove',
      'exerciseRemoveLast'
    ])
  }
}
</script>
